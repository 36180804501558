import { Close20 } from "@carbon/icons-react";
import { Center } from "components/atoms/Center";
import { FilterCountrySelection } from "components/atoms/Table/TableFilter/components/FilterCountrySelection";
import { FilterTrackingSelection } from "components/atoms/Table/TableFilter/components/FilterTrackingSelection";
import { FilterStoreNameSelection } from "components/atoms/Table/TableFilter/components/FilterStoreNameSelection";
import { FilterSkuQuantitySelection } from "components/atoms/Table/TableFilter/components/FilterSkuQuantitySelection";
import { FilterGeneric } from "components/atoms/Table/TableFilter/components/FilterGeneric";
import { FilterLookup } from "components/atoms/Table/TableFilter/components/FilterLookup";
import {
  Filter,
  FilterTypes,
} from "components/atoms/Table/TableFilter/types/filter.types";
import { apiPaths } from "enum/paths.enum";
import { IBulkShipment } from "models/bulkShipment/bulkShipment.model";
import { ILabelJob } from "models/labelJob/labelJob.model";
import { IShipment } from "models/shipment/shipment.model";
import { FaFile, FaMapMarkerAlt, FaClock } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  changeBulkUploadFilterThunk,
  changeFromCountryIsoFilterThunk,
  changeLabelJobFilterThunk,
  changeToCountryIsoFilterThunk,
  changeDateRangeFilterThunk,
  changeTrackingStatusFilterThunk,
  changeStoreNameFilterThunk,
} from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { formatDate } from "utils/formatDate";
import { shipmentLabelsInProgress } from "./utils/Shipments.utils";
import { DatePickerComponent } from "components/atoms/datePicker/antdDatePicker";
import { NumberOfDaysComponent } from "components/atoms/datePicker/numberOfDaysPicker";
import { FormControl } from "components/molecules/FormControl";
import { changeDateRangeDaysThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import dayjs from "dayjs";

export const ShipmentFilters = () => {
  const dispatch = useDispatch();

  const { items: labelJobs } = useSelector((state: RootState) => {
    return state.currentLabelJobsSlice;
  });

  const { meta, query: shipmentsQuery } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const unselectable = [];

  meta?.shipmentIds?.map((id) => {
    if (shipmentLabelsInProgress(labelJobs).includes(id)) {
      unselectable.push(id);
    }
  });

  const shipmentBulkUpload: Filter<
    Pick<IBulkShipment, "id" | "uuid" | "filename" | "createdDate">
  > = {
    component: FilterLookup,
    icon: FaFile,
    type: FilterTypes.lookup,
    title: "Bulk upload file name",
    endpoint: apiPaths.bulkShipments,
    value: shipmentsQuery.filters.bulkShipment,
    itemComponent: (item) => {
      return (
        <div className="flex items-center w-full">
          <div className="flex-1 leading-none">{item.filename}</div>
          <div className="ml-auto text-xs leading-none text-gray10">
            {formatDate(item.createdDate)}
          </div>
        </div>
      );
    },
    onChange: (item) => {
      dispatch(changeBulkUploadFilterThunk(item));
    },
    valueComponent: (item) => {
      return (
        <div className="flex items-center pl-3 text-sm leading-none border rounded-md h-9 bg-gray5 border-gray7">
          <div className="leading-none">{item.filename}</div>
          <div
            className="ml-auto cursor-pointer w-9 h-9"
            onClick={() => {
              dispatch(changeBulkUploadFilterThunk(null));
            }}
          >
            <Center>
              <Close20 />
            </Center>
          </div>
        </div>
      );
    },
  };

  const StoreName: Filter<IShipment> = {
    component: FilterStoreNameSelection,
    type: FilterTypes.stores,
    icon: FaFile,
    title: "Store Name",
    value: shipmentsQuery.filters.integrationStoreId,
    onChange: (item) => {
      dispatch(changeStoreNameFilterThunk(item));
    },
  };

  const fromAddressCountryIso: Filter<IShipment> = {
    component: FilterCountrySelection,
    type: FilterTypes.country,
    icon: FaMapMarkerAlt,
    title: "Origin country",
    value: shipmentsQuery.filters.fromAddressCountryIso,
    onChange: (item) => {
      dispatch(changeFromCountryIsoFilterThunk(item));
    },
  };

  const toAddressCountryIso: Filter<IShipment> = {
    component: FilterCountrySelection,
    icon: FaMapMarkerAlt,
    type: FilterTypes.country,
    title: "Destination country",
    value: shipmentsQuery.filters.toAddressCountryIso,
    onChange: (item) => {
      dispatch(changeToCountryIsoFilterThunk(item));
    },
  };
  // Needs rewriting currently not working
  // const trackingStatus: Filter<IShipment> = {
  //   component: FilterTrackingSelection,
  //   type: FilterTypes.tracking,
  //   icon: FaMapMarkerAlt,
  //   title: "Tracking status",
  //   value: shipmentsQuery.filters.trackingStatus,
  //   onChange: (item) => {
  //     dispatch(changeTrackingStatusFilterThunk(item));
  //   },
  // };

  const dateRange: Filter<{ fromDate: string; toDate: string }> = {
    component: FilterGeneric,
    icon: FaClock,
    type: FilterTypes.generic,
    title: "Date Range",
    value: {
      fromDate: shipmentsQuery.filters.fromDate,
      toDate: shipmentsQuery.filters.toDate,
    },
    onChange: () => {
      return;
    },
    valueComponent: () => {
      const fromDate = shipmentsQuery.filters.fromDate
        ? dayjs(new Date(shipmentsQuery.filters.fromDate))
        : null;

      const toDate = shipmentsQuery.filters.toDate
        ? dayjs(new Date(shipmentsQuery.filters.toDate))
        : null;
      return (
        <div className="flex flex-col">
          <NumberOfDaysComponent
            todayOnChange={() => {
              dispatch(changeDateRangeDaysThunk("1d"));
            }}
            sevenDayOnChange={() => {
              dispatch(changeDateRangeDaysThunk("7d"));
            }}
            monthOnchange={() => {
              dispatch(changeDateRangeDaysThunk("30d"));
            }}
            yearOnChange={() => {
              dispatch(changeDateRangeDaysThunk("1y"));
            }}
            location={"shipments"}
          />
          <div className="w-full mt-4">
            <FormControl label="From" isRequired={false} className={"w-full"}>
              <DatePickerComponent
                onChange={(from) => {
                  dispatch(
                    changeDateRangeFilterThunk(
                      from["$d"].toISOString(),
                      shipmentsQuery.filters.toDate
                    )
                  );
                }}
                value={fromDate}
              />
            </FormControl>
          </div>
          <div className="flex mt-4 w-full">
            <FormControl label="To" isRequired={false} className={"w-full"}>
              <DatePickerComponent
                onChange={(to) => {
                  dispatch(
                    changeDateRangeFilterThunk(
                      shipmentsQuery.filters.fromDate,
                      to["$d"].toISOString()
                    )
                  );
                }}
                value={toDate}
              />
            </FormControl>
          </div>
        </div>
      );
    },
  };

  const labelJob: Filter<Pick<ILabelJob, "id" | "uuid">> = {
    component: FilterGeneric,
    type: FilterTypes.generic,
    icon: FaFile,
    title: "Label job",
    value: shipmentsQuery.filters.labelJob,
    onChange: (item) => {
      dispatch(changeLabelJobFilterThunk(item));
    },
    valueComponent: (item) => {
      return (
        <div className="flex items-center pl-3 text-sm leading-none border rounded-md h-9 bg-gray5 border-gray7">
          <div className="leading-none">Label job{item.id}</div>
          <div
            className="ml-auto cursor-pointer w-9 h-9"
            onClick={() => {
              dispatch(changeLabelJobFilterThunk(null));
            }}
          >
            <Center>
              <Close20 />
            </Center>
          </div>
        </div>
      );
    },
  };

  const SkuAndQuantity: Filter<any> = {
    component: FilterGeneric,
    type: FilterTypes.generic,
    icon: FaFile,
    title: "Item SKU",
    value: {
      sku: shipmentsQuery.filters.sku,
      quantity: shipmentsQuery.filters.quantity,
    },
    onChange: () => {
      return;
    },
    valueComponent: () => {
      return (
        <div className="flex-col">
          <FilterSkuQuantitySelection
            sku={shipmentsQuery.filters.sku}
            quantity={shipmentsQuery.filters.quantity}
          />
        </div>
      );
    },
  };

  return {
    dateRange,
    StoreName,
    // ...(shipmentsQuery.filters.statuses[0] === "label_created" && {
    //   trackingStatus,
    // }),
    shipmentBulkUpload,
    fromAddressCountryIso,
    toAddressCountryIso,
    ...(shipmentsQuery.filters.labelJob && { labelJob }),
    SkuAndQuantity,
  };
};
